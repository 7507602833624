/* .navbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
} */

/* #dropdownMenuLoc,
#dropdownMenuMore {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
  border-style: none;
  font-weight: normal;
  font-size: 1em;
} */

#dropdownMenuLoc:hover {
  color: rgba(0, 0, 0, 0.8);
}

.dropdown-menu {
  max-width: 2em;
}
.dropdown-icon {
  width: 15px;
  height: 15px;
  margin-left: 50px;
}
