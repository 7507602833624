#body {
  margin-top: 10%;
}
@media (min-width: 800px) {
  #login-form {
    padding: 5rem 6rem;
  }
}

.password-field {
  display: flex;
  position: relative;
}
.eye-icon {
  width: 2em;
  height: 1.5em;
}
.show-password-btn {
  position: absolute;
  right: 1em;
  top: 0.65em;
}
.show-password-btn:hover {
  color: #007bff;
}
