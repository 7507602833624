.masthead {
  height: 100vh;
  background-image: url("https://static.wixstatic.com/media/bc116e_147a35eb86364cdd878c4d4b198b8ef0~mv2.png/v1/fill/w_1350,h_642,al_c,q_90/bc116e_147a35eb86364cdd878c4d4b198b8ef0~mv2.webp");
  background-size: 100%;
  background-repeat: repeat;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #1ca3f0;
  font-weight: bold;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #333333;
  /* --bs-btn-color: var(--bs-white); */
  --bs-btn-bg: #1ca3f0;
  --bs-btn-border-color: #188fd4;
  /* --bs-btn-border-radius: 0.5rem; */
  --bs-btn-hover-color: #333333;
  /* --bs-btn-hover-color: var(--bs-white); */
  /* --bs-btn-hover-bg: #004aad; */
  --bs-btn-hover-bg: #45b9fc;
  --bs-btn-hover-border-color: #188fd4;
  --bs-btn-focus-shadow-rgb: #188fd4;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #188fd4;
  --bs-btn-active-border-color: #188fd4;
  --bs-btn-disabled-bg: #1ca3f0;
  --bs-btn-disabled-color: #black;
  --bs-btn-disabled-border-color: #45b9fc;
}

.btn-outline-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #1ca3f0;
  --bs-btn-border-color: #1ca3f0;
  --bs-btn-hover-bg: #1ca3f0;
  --bs-btn-hover-color: #1ca3f0;
  --bs-btn-hover-border-color: #1ca3f0;
  --bs-btn-hover-color: black;
  --bs-btn-disabled-bg: #adb5bd;
  --bs-btn-disabled-color: #black;
}

.btn-warning {
  --bs-btn-font-weight: 600;
  --bs-btn-bg: #f08021;
  --bs-btn-hover-bg: #fd9e4b;
}

.btn-outline-warning {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #f08021;
  --bs-btn-border-color: #f08021;
  --bs-btn-hover-bg: #fd9e4b;
  --bs-btn-hover-color: #fd9e4b;
  --bs-btn-hover-border-color: #fd9e4b;
  --bs-btn-hover-color: black;
}

.text-primary {
  color: #004aad !important;
  /* color: #1ca3f0 !important; */
  font-weight: 700;
}

.form-control:focus {
  border-color: #1ca3f0;
  box-shadow: 0 0 0 0.25rem rgba(28, 162, 240, 0.384);
}

input[type="radio"]:checked {
  background-color: #1ca3f0;
  border-color: #1ca3f0;
}

input[type="radio"]:not(:checked) {
  background-color: #eeeeee;
  /* border-color: #000000; */
}
