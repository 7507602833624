/* Ensure the html and body elements take up the full height */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Flex container for the main layout */
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Flex item for the body to fill the available space */
.flex-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ensure the card and its parent div take up the full height */
.full-height {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.scorecard-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  min-width: 130px;
}

.plot-card-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.spectrum-card-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.result-card {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
}

/* Ensure the buttons are hidden by default */
.result-card .hover-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* bottom: -10px; 
  left: 0; */
  top: 100%; /* Start at the bottom of the card */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the buttons */
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}

.result-card:hover {
  color: #ced4da;
  position: relative; /* Ensure the card is positioned relative to contain the absolute positioned buttons */
}

.result-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom center;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  z-index: -1;
  transition: transform 0.5s;
}
.result-card:hover::after {
  transform: scaleY(1);
}

/* Display the buttons when hovering over the card */
.result-card:hover .hover-buttons {
  /* bottom: 45px; Move up into view */
  top: 50%; /* Move to the center of the card */
  opacity: 1;
}
